<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: page_route ? `${page_route}.detalle` : 'home',
              params: { id: rha_id },
            }"
          />
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${!loading ? `(${data_table.length})` : ''}`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <BtnCircle
            v-if="$store.getters.getLogin.permissions.rha_loas.create"
            tLabel="Agregar"
            tPosition="bottom"
            icon="mdi-plus"
            color="success"
            size="x-small"
            :click="formAdd"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-row>
        <v-col cols="12">
          <RhaData :rha_id="rha_id" />
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'REGISTROS'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-text="'#'" />
                      <th v-text="'Descripción'" />
                      <th v-text="'Acción'" width="150px" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in data_table" :key="i">
                      <td
                        class="font-weight-bold"
                        v-text="data_table.length - i"
                      />
                      <td
                        class="text-description"
                        v-text="
                          item.description
                            ? item.description.length > 300
                              ? `${item.description.substr(0, 300)}...`
                              : item.description
                            : ''
                        "
                      />
                      <td width="140px">
                        <v-tooltip left v-if="true">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="mr-1"
                              v-on="on"
                              fab
                              x-small
                              color="secondary"
                              dark
                              @click="formEditView(item.id, false)"
                            >
                              <v-icon v-text="'mdi-eye'" />
                            </v-btn>
                          </template>
                          <span v-text="'Ver'" />
                        </v-tooltip>
                        <v-tooltip
                          left
                          v-if="
                            $store.getters.getLogin.permissions.rha_loas.update
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="mr-1"
                              v-on="on"
                              fab
                              x-small
                              color="info"
                              dark
                              @click="formEditView(item.id, true)"
                            >
                              <v-icon v-text="'mdi-pencil'" />
                            </v-btn>
                          </template>
                          <span v-text="'Editar'" />
                        </v-tooltip>
                        <v-tooltip
                          left
                          v-if="
                            $store.getters.getLogin.permissions.rha_loas.delete
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="mr-1"
                              v-on="on"
                              fab
                              x-small
                              color="error"
                              dark
                              @click="desactivate(item.id)"
                            >
                              <v-icon v-text="'mdi-delete'" />
                            </v-btn>
                          </template>
                          <span v-text="'Eliminar'" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- Dialog STOREUPDATE start -->
    <v-dialog v-model="form_dialog" scrollable persistent max-width="1200px">
      <v-card
        tile
        :loading="loading ? 'grey darken-2' : false"
        :disabled="loading"
      >
        <v-toolbar dark>
          <v-toolbar-title v-text="data.id ? 'Editar' : 'Agregar'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="formDialogClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="form_dialog">
          <v-form v-on:submit.prevent ref="form_submit" lazy-validation>
            <v-row>
              <v-col cols="12" class="mt-3">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2 class="text-caption" v-text="'LOA'" />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <v-textarea
                          v-model="data.description"
                          label="Descripción"
                          row="1"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2 class="text-caption" v-text="'DOCUMENTOS'" />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col
                        cols="12"
                        v-for="(document, i) in data.documents"
                        :key="i"
                      >
                        <v-row v-if="document.active">
                          <v-col cols="12" sm="12" md="3">
                            <v-autocomplete
                              :label="`Tipo (${i + 1})*`"
                              v-model="document.document_id"
                              :rules="rules.required"
                              :items="documents"
                              item-value="id"
                              :item-text="(item) => item.document"
                              :loading="documents_loading"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="5">
                            <v-text-field
                              label="Descripción"
                              v-model="document.description"
                              type="text"
                            />
                          </v-col>
                          <v-col cols="12" xs="12" md="3">
                            <br />
                            <input
                              accept=".pdf, .docx, .doc, .txt, .xlsx, .xls"
                              label="Archivo digital*"
                              type="file"
                              @change="fileSelect($event, i)"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            xs="12"
                            md="1"
                            class="text-center"
                            align-self="center"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  text
                                  icon
                                  x-small
                                  color="error"
                                  @click="fileDelete(i)"
                                >
                                  <v-icon medium v-text="'mdi-close'" />
                                </v-btn>
                              </template>
                              <span v-text="`Eliminar documento (${i + 1})`" />
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          @click="fileAdd"
                          color="warning"
                          :loading="loading"
                          x-small
                          block
                          dark
                        >
                          <v-icon left v-text="'mdi-file-plus'" />
                          Agregar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  :color="data.id ? 'info' : 'success'"
                  @click="formSubmit"
                >
                  <v-icon v-text="`mdi-${data.id ? 'pencil' : 'plus'}`" left />
                  Aceptar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog STOREUPDATE end -->
    <!-- Dialog VIEW start -->
    <v-dialog v-model="view_dialog" scrollable persistent max-width="1200px">
      <v-card tile>
        <v-toolbar dark>
          <v-toolbar-title v-text="'Ver'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="view_dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="view_dialog">
          <v-row>
            <v-col cols="12" class="mt-3">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'LOA'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <ViewData
                        label="Descripción"
                        :value="data.description"
                        class="text-description"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'DOCUMENTOS'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense v-for="(document, i) in data.documents" :key="i">
                    <v-col cols="12" sm="12" md="4">
                      <ViewData
                        :label="`Tipo (${i + 1})`"
                        :value="document.document ? document.document : ''"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <ViewData
                        label="Descripción"
                        :value="document.description"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="ml-1"
                            v-on="on"
                            fab
                            x-small
                            color="warning"
                            dark
                            :href="url_documents + '/rha_loas/' + document.url"
                            target="_blank"
                          >
                            <v-icon v-text="'mdi-download'" />
                          </v-btn>
                        </template>
                        <span v-text="'Descargar'" />
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12">
                      <v-divider />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog VIEW end -->
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
  URL_DOCUMENTS,
} from "../../control";
import { catalog } from "../../requests/catalogRequest";
import { storeUpdate, show, index, destroy } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import ViewData from "../../components/ViewData.vue";
import RhaData from "../../components/RhaData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    ViewData,
    RhaData,
    FaqDlg,
  },
  data() {
    return {
      page_route: null,
      api: "rha/loas",
      url_documents: URL_DOCUMENTS,
      login: this.$store.getters.getLogin,
      rha_id: this.$route.params.id,
      loading: true,
      rules: rules(),
      data_table: [],
      data: {},
      form_dialog: false,
      view_dialog: false,
      documents: [],
      documents_loading: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getDataTable() {
      this.loading = true;

      index(this.api, this.login.token, [
        {
          name: "active",
          value: 1,
        },
        {
          name: "id",
          value: this.login.id,
        },
        {
          name: "rha_id",
          value: this.rha_id,
        },
      ]).then((response) => {
        this.data_table = response.data;
        this.loading = false;
      });
    },
    defaultData() {
      this.data = {
        id: null,
        created_by_id: this.login.id,
        updated_by_id: this.login.id,
        rha_id: this.$attrs.id,
        documents: [],
      };
    },
    formAdd() {
      this.defaultData();
      this.form_dialog = true;
    },
    formEditView(id, edit) {
      show(this.api, this.login.token, id).then((response) => {
        this.data = response;

        if (edit) {
          this.form_dialog = true;
        } else {
          this.view_dialog = true;
        }
      });
    },
    formDialogClose() {
      this.form_dialog = false;
      this.defaultData();
      this.$refs.form_submit.resetValidation();
    },
    formSubmit() {
      if (this.$refs.form_submit.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma ${this.data.id ? "editar" : "agregar"} el registro?`
            )
          )
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;
              storeUpdate(
                this.api,
                this.login.token,
                this.id,
                this.data,
                this.login.id
              ).then((response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                if (response.success) {
                  this.getDataTable();
                  this.defaultData();
                  this.form_dialog = false;
                } else {
                  console.log(response.message);
                  this.loading = false;
                  this.form_dialog = true;
                }
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    desactivate(id) {
      this.$swal
        .fire(msgConfirm("¿Confirma la desactivación del registro?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            destroy(this.api, this.login.token, id, this.login.id).then(
              (response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                response.success
                  ? this.getDataTable()
                  : console.log(response.message);
              }
            );
          }
        });
    },
    fileAdd() {
      this.data.documents.push({
        id: null,
        description: "",
        active: true,
      });
    },
    fileSelect(event, i) {
      this.data.documents[i].file = event.target.files[0];
    },
    fileDelete(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar el documento (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.documents[i].id === null) {
              this.data.documents.splice(i, 1);
            } else {
              this.data.documents[i].active = false;
            }
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.page_route = this.$route.name;
    this.page_route = this.page_route.split(".");
    this.page_route = this.page_route[0];

    index("documents/6", this.login.token, []).then((response) => {
      this.documents = response.data;
      this.documents_loading = false;
    });

    this.getDataTable();
  },
};
</script>

<style>
.text-description {
  white-space: pre-line;
}
</style>